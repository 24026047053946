import Skills from "./Skills";
import PersonalIntro from "./PersonalIntro";

const About = () =>{

    return <div  className='page'>
               
       {/* <PersonalIntro/> */}



       <Skills/>

{/*     <made with.. */}


    </div>
}

export default About;