import Timeline from "./Timeline/Timeline";
import './Timeline/timeline.css';



const Prj = () =>{
    return <div className="story-container">
           <Timeline />
    </div>
}

export default Prj;